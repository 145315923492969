import _mergeJSXProps2 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps from "@vue/babel-helper-vue-jsx-merge-props";
import _objectWithoutProperties from "D:/workspace/emind-ui-1.0/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.search.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import datePickerLocale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import axios from 'axios';
import moment from 'moment';
import $ from 'jquery';
import form from 'jquery-form';
var equipTypeList = [];
var columns = [{
  title: '操作模块',
  dataIndex: 'parentName',
  width: 80,
  scopedSlots: {
    customRender: '操作模块'
  }
}, {
  title: '操作名称',
  dataIndex: 'name',
  width: 80,
  scopedSlots: {
    customRender: '操作名称'
  }
}, {
  title: '操作人',
  dataIndex: 'operator',
  width: 80,
  scopedSlots: {
    customRender: '操作人'
  }
}, {
  title: '触发类型',
  dataIndex: 'triggerType',
  width: 80,
  scopedSlots: {
    customRender: '触发类型'
  },
  customRender: function customRender(triggerType) {
    switch (triggerType) {
      case 0:
        return '操作前';
        break;

      case 1:
        return '操作后';
        break;

      case 2:
        return '异常';
        break;

      default:
        return '未知';
    }
  }
}, {
  title: '操作时间',
  dataIndex: 'recordTime',
  width: 120,
  scopedSlots: {
    customRender: '操作时间'
  }
}, {
  title: '描述',
  dataIndex: 'description',
  width: 120,
  scopedSlots: {
    customRender: '描述'
  }
}];
var draggingMap = {};
columns.forEach(function (col) {
  draggingMap[col.Id] = col.width;
});
var draggingState = Vue.observable(draggingMap);
var ResizeableTitle = {
  functional: true,
  render: function render(h, h1) {
    var props = h1.props;
    var children = h1.children;
    var thDom = null;

    var key = props.key,
        restProps = _objectWithoutProperties(props, ["key"]);

    var col = columns.find(function (col) {
      var k = col.dataIndex || col.key;
      return k === key;
    });

    if (!col || !col.width) {
      return h("th", _mergeJSXProps([{}, restProps]), [children]);
    }

    var onDrag = function onDrag(x, y) {
      draggingState[key] = 0;
      col.width = Math.max(x, 1);
    };

    var onDragstop = function onDragstop() {
      draggingState[key] = thDom.getBoundingClientRect().width;
    };

    return h("th", _mergeJSXProps2([{}, restProps, {
      "directives": [{
        name: "ant-ref",
        value: function value(r) {
          return thDom = r;
        }
      }],
      "attrs": {
        "width": col.width
      },
      "class": "resize-table-th"
    }]), [children, h("vue-draggable-resizable", {
      "key": col.rowKey,
      "class": "table-draggable-handle",
      "attrs": {
        "w": 10,
        "x": draggingState[key] || col.width,
        "z": 1,
        "axis": "x",
        "draggable": true,
        "resizable": false
      },
      "on": {
        "dragging": onDrag,
        "dragstop": onDragstop
      }
    })]);
  }
};
export default {
  data: function data() {
    var _this = this;

    this.components = {
      header: {
        cell: ResizeableTitle
      }
    };
    return {
      pagination: {
        defaultPageSize: 50,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10', '25', '50', '100'],
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          return _this.pageSize = pageSize;
        }
      },
      columns: columns,
      dateFormat: 'YYYY-MM-DD',
      scroll: {
        //					x: 1200, 
        y: 660
      },
      datePickerLocale: datePickerLocale,
      search: {
        startTime: moment(),
        endTime: moment(),
        length: 1000000000,
        page: 1,
        startIndex: 0
      },
      tableData3: [],
      total: 0,
      loading: false,
      showTotal: true
    };
  },
  mounted: function mounted() {
    var token = localStorage.getItem("currentUser_token");

    if (!token && typeof token != "undefined" && token != 0) {
      this.$router.replace({
        path: '/'
      });
      return;
    }

    var id = localStorage.getItem('stationId');

    if (!id && typeof id != "undefined" && id != 0) {
      this.$router.replace({
        path: '/distribution'
      });
    }

    this.getList();
  },
  methods: {
    //获取刷新列表数据
    getList: function getList() {
      var this_ = this;
      var data = {
        length: this.search.length,
        page: this.search.page,
        startIndex: this.search.startIndex
      };

      if (this.search.startTime != null && this.search.endTime != null && this.search.startTime.toDate().getTime() > this.search.endTime.toDate().getTime()) {
        this.$message.error('结束时间早于起始时间，请重新设置查询时间！');
        return;
      } else {
        var dataTime = '';

        if (this_.search.startTime != null) {
          dataTime = this_.search.startTime.format('YYYY-MM-DD ') + "00:00:00";
          data.startTime = dataTime;
        } else {
          data.startTime = '1970-01-01 00:00:00';
        }

        if (this_.search.endTime != null) {
          dataTime = this_.search.endTime.format('YYYY-MM-DD ') + "23:59:59";
          data.endTime = dataTime;
        } else {
          if (this_.search.startTime.toDate().getTime() > new Date().getTime()) {} else {
            data.endTime = moment().format('YYYY-MM-DD ') + "23:59:59";
          }
        }
      }

      data.startIndex = (data.page - 1) * data.length;
      this_.loading = true;
      axios({
        method: 'post',
        url: '/emind/log/operation/search',
        data: data
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        this_.total = d.data.recordsTotal;
        this_.tableData3 = d.data.data;

        for (var i = 0; i < this_.tableData3.length; i++) {
          this_.tableData3[i].key = i;
        }

        this_.loading = false;
      }).catch(function (error) {
        this_.loading = false;

        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    searchFn: function searchFn() {
      var this_ = this;
      this_.getList();
    }
  }
};